/* nunito-200 */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/Nunito/Nunito-ExtraLight.ttf') format('truetype');
}

/* nunito-200 italic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/Nunito/Nunito-ExtraLightItalic.ttf') format('truetype');
}

/* nunito-300 */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Nunito/Nunito-Light.ttf') format('truetype');
}

/* nunito-300italic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/Nunito/Nunito-LightItalic.ttf') format('truetype');
}

/* nunito-regular */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Nunito/Nunito-Regular.ttf') format('truetype');
}

/* nunito-italic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/Nunito/Nunito-Italic.ttf') format('truetype');
}

/* nunito-600 */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
}

/* nunito-600 italic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/Nunito/Nunito-SemiBoldItalic.ttf') format('truetype');
}

/* nunito-700 */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Nunito/Nunito-Bold.ttf') format('truetype');
}

/* nunito-700 italic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/Nunito/Nunito-BoldItalic.ttf') format('truetype');
}

/* nunito-800 */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/Nunito/Nunito-ExtraBold.ttf') format('truetype');
}

/* nunito-800 italic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/Nunito/Nunito-ExtraBoldItalic.ttf') format('truetype');
}

/* nunito-900 */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/Nunito/Nunito-Black.ttf') format('truetype');
}

/* nunito-900 italic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  src: url('./fonts/Nunito/Nunito-BlackItalic.ttf') format('truetype');
}

/* open_sans-300 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
}

/* open_sans-300italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype');
}

/* open_sans-regular */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

/* open_sans-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
}

/* open_sans-600 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

/* open_sans-600 italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
}

/* open_sans-700 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}

/* open_sans-700 italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
}

/* open_sans-800 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
}

/* open_sans-800 italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}

/* caveat-700 */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Caveat/Caveat-Bold.ttf') format('truetype');
}

/* caveat-600 */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Caveat/Caveat-SemiBold.ttf') format('truetype');
}

/* caveat-500 */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Caveat/Caveat-Medium.ttf') format('truetype');
}

/* caveat-400 */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Caveat/Caveat-Regular.ttf') format('truetype');
}

/* poppins-400 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

/* poppins-500 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

/* poppins-600 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

/* poppins-700 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}
